<template>
  <!-- 解决方案 -->
  <div class="website-css">
    <MenuHead @func="getMsgFormSon"></MenuHead>
    <!-- banner beg -->
    <div class="Solution-banner AboutUs-banner">
      <div class="w1200">
        <img
          src="../assets/image/Website-img/bannericon1.png"
          class="banner-icon banner-icon1 opacity2"
        />
        <img
          src="../assets/image/Website-img/bannericon2.png"
          class="banner-icon banner-icon2 animated pulse"
        />
        <div
          class="banner-title solution-title Mobile-pad25 banner-title-white animated fadeInDown"
        >
          <p>{{ this.languageList[0].bannertitle }}</p>
          <p>
            {{ this.languageList[0].bannerContent }}<br />{{
              this.languageList[0].bannerContent1
            }}
          </p>
        </div>
        <!-- <img src="../assets/image/Website-img/bannericon1.png" class="banner-icon3" /> -->
      </div>
    </div>
    <!-- banner end -->

    <div class="w1200 Mobile-pad25">
      <!-- 大脑综合评估与提升 beg -->
      <div class="brain">
        <div class="title-font36">
          {{ this.languageList[0].titleText }}
          <span></span>
        </div>
        <div class="title-font18">
          {{ this.languageList[0].textContent }}
        </div>
        <ul class="brain-ul clearfix">
          <li v-for="(item, index) in this.languageList[0].List" :key="index">
            <div class="w-simImg simImg_spec imgScaleBig simImg_spec4">
              <div class="image-w">
                <div class="imgBB">
                  <div class="img">
                    <img :src="item.img" />
                  </div>
                </div>
                <div class="caption w-img-caption">
                  <div class="captionbg bg_main"></div>
                  <div class="caption-text">
                    <div class="caption-text-in">
                      <h3>{{ item.title }}</h3>
                      <p>
                        {{ item.content }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- 大脑综合评估与提升 end -->

      <!-- 相关案例 beg -->
      <div class="title-font36">
        {{ this.languageList[0].cases[0].titleText }}
        <span></span>
      </div>
      <ul class="Related-Cases">
        <li
          v-for="(item, index) in this.languageList[0].cases[0].list"
          :key="index"
        >
          <div><img :src="item.img" /></div>
          <p>{{ item.title }}</p>
          <p>{{ item.content }}</p>
        </li>
      </ul>
      <!-- 相关案例 end -->
    </div>
    <web-bottom ref="child"></web-bottom>
  </div>
</template>
<script>
import '../assets/style/Public-css/img-style.css'
import ChineseJson from '../assets/json/Chinese-Json.json'
import EnglishJson from '../assets/json/English-Json.json'
import MenuHead from '../components/MenuHead'
import WebBottom from '../components/WebBottom'
export default {
  metaInfo: {
    title: '解决方案-北京大象智酷科技有限公司', // set a title
    meta: [
      // set meta
      {
        name: 'keyWords',
        content: '大脑能力评估,脑科学,神经科学',
      },
      {
        name: 'description',
        content:
          '大象智酷科研团队依靠认知神经科学、脑科学、认知心理学等学科成熟理论,以数字云平台的大数据为依托,为不同行业群体提供针对性大脑能力评估和提升一体化方案.通过全方位、多能力的认知能力测试,实时采集相应数据,提供专业反馈信息和提升建议,用脑科学提升行业人群质量,赋能行业发展.',
      },
    ],
    // link: [{                 // set link
    //  		rel: 'asstes',
    //   	href: 'https://assets-cdn.github.com/'
    // }]
  },
  name: 'Solution',
  components: {
    MenuHead,
    WebBottom,
  },

  data() {
    return {
      ChineseJson: JSON.parse(JSON.stringify(ChineseJson)), //中文配表
      EnglishJson: JSON.parse(JSON.stringify(EnglishJson)), //英文配表
      languageJson: [],
      languageList: [],
    }
  },
  props: {
    msg: String,
  },
  created() {
    // this.langtype = localStorage.getItem("LanguageTypes");
    // console.log("语言类型", this.langtype);
    // this.IFType();
    if (localStorage.getItem('LanguageTypes') == undefined) {
      this.langtype = 1
      this.IFType()
    } else {
      this.langtype = localStorage.getItem('LanguageTypes')
      console.log('语言类型', this.langtype)
      this.IFType()
    }
  },
  mounted() {},
  methods: {
    IFType() {
      if (this.langtype == 1) {
        this.languageJson = this.ChineseJson
        console.log('中文配表', this.languageJson)
        this.getList()
      } else {
        this.languageJson = this.EnglishJson
        this.getList()
      }
    },
    getMsgFormSon(data) {
      // this.msgFormSon = data;
      console.log('home', data)
      this.langtype = data
      this.IFType()
      this.$refs.child.sing(this.langtype)
    },
    getList() {
      // this.ChineseJson.fo
      this.languageJson.forEach((item) => {
        if (item.title == 'Solution') {
          this.languageList = item.List
        }
      })
    },
  },
}
</script>
<style scoped>
.Solution-banner {
  width: 100%;
  height: 535px;
  background: linear-gradient(to right, #00173f, #000715);
}
.Solution-banner {
}
.brain-ul li {
  float: left;
  width: 400px;
  word-wrap: break-word;
  word-break: normal;

  height: 300px;
}
</style>